import React from 'react';
import Countdown from 'react-countdown';

const CountDown = ({ date, onFinish }) => {
    const rendererCountDown = ({
        hours,
        minutes,
        seconds,
        completed,
        days
    }) => {
        if (completed) {
            if (typeof onFinish === 'function') {
                onFinish();
            }
            return null;
        } else {
            // Render a countdown
            return (
                <div className="wrap-countDown mb-5">
                    <p> El evento iniciará la venta en </p>
                    <div className="counters">
                        <div>
                            <span>{days}</span>
                            <span>Días</span>
                        </div>
                        <div>
                            <span>{`${
                                hours < 10 ? `0${hours}` : `${hours}`
                            }`}</span>
                            <span>Horas</span>
                        </div>
                        <div>
                            <span>{`${
                                minutes < 10 ? `0${minutes}` : `${minutes}`
                            }`}</span>
                            <span>Minutos</span>
                        </div>
                        <div>
                            <span>{`${
                                seconds < 10 ? `0${seconds}` : `${seconds}`
                            }`}</span>
                            <span>Segundos</span>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return (
        <>
            <div className="countDown">
                <Countdown date={String(date)} renderer={rendererCountDown} />
            </div>
        </>
    );
};

export default CountDown;
